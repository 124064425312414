/* SearchBar.css */

.search-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    position: relative;
}

.search-bar input[type="text"] {
    width: 300px;
    padding: 12px 20px;
    border: 2px solid #ccc;
    border-radius: 50px;
    font-size: 16px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 10px -4px rgba(0, 0, 0, 0.1);
}

.search-bar input[type="text"]:focus {
    border-color: var(--accent-color);
    box-shadow: 0 4px 10px -4px rgba(19, 98, 7, 0.2);
    outline: none;
    transform: scale(1.02);
}

.search-button {
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--accent-color);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    transition: color 0.3s, transform 0.3s;
    position: absolute;
    right: 10px;
}

.search-button:hover {
    color: var(--royal-blue);
    transform: scale(1.2);
}
