#checkout-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    gap: 20px;
    font-family: 'Noto Serif', serif;
}

#checkout-content {
    flex: 1;
    padding: 20px 125px;
}

#checkout-content h1 {
    font-family: 'Noto Serif', serif;
    color: var(--royal-blue);
    text-align: left;
    font-size: 2.5rem;
    border-bottom: 2px solid var(--accent-color);
    padding-bottom: 10px;
    width: 100%;
    margin-bottom: 20px;
}

#checkout-items {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.checkout-item {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
    padding: 20px 0;
}

.checkout-item img {
    width: 100%;
    max-width: 150px;
    height: auto;
    object-fit: cover;
    margin-right: 20px;
    margin-left: 20px;
    flex-shrink: 0;
}

.checkout-item-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#checkout-summary {
    margin-top: 20px;
    padding: 20px;
    background-color: #f0f4f8;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

#checkout-summary textarea {
    width: calc(100% - 20px);
    max-width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
    resize: vertical;
    font-family: 'Noto Serif', serif;
    font-size: 1rem;
    line-height: 1.5;
    background-color: #f9f9f9;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

#checkout-summary textarea:focus {
    border-color: var(--accent-color);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    outline: none;
}

#place-order-button {
    padding: 12px 20px;
    background-color: var(--accent-color);
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1.8rem;
    width: 100%;
    text-align: center;
    font-family: 'Great Vibes', cursive;
}

#place-order-button:hover {
    background-color: #3b5998;
}
