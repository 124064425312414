/* Footer.css */

footer {
    text-align: center;
    margin: 0;
    flex-shrink: 0;
    padding: 15px 0;
    background-color: #fff;
    font-size: 0.9rem;
    color: #666;
    border-top: 1px solid #eaeaea;
    position: relative;
    overflow: hidden;
}

footer::before {
    content: '';
    position: absolute;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle at center, rgba(19, 98, 7, 0.1), transparent);
    animation: footerAnimation 20s linear infinite;
    top: -50%;
    left: -50%;
}

@keyframes footerAnimation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
