#add-item-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    gap: 80px; /* This setting controls the space between the header and the footer */
    font-family: 'Noto Serif', serif;
}

#pycommerce-title {
    font-size: 4rem !important;
    text-align: center !important;
    margin-bottom: 0;
    padding: 0;
    line-height: 1;
    font-family: 'Great Vibes', cursive !important;
    transition: transform 0.3s ease !important;
}

#add-item-container {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    width: 90%;
    max-width: 400px;
    margin: 1.5rem auto;
    margin-bottom: auto;
    transition: transform 0.3s ease;
}

#add-item-container:hover {
    transform: translateY(-5px);
}

#add-item-container h2 {
    color: var(--royal-blue);
    margin-bottom: 1.5rem;
    margin-top: 0;
    font-size: 1.8rem;
    position: relative;
}

#add-item-container h2::after {
    content: '';
    width: 50px;
    height: 3px;
    background-color: var(--accent-color);
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}

#add-item-container input {
    margin-bottom: 1rem;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

#add-item-container input:focus {
    border-color: var(--accent-color);
    box-shadow: 0 0 8px rgba(19, 98, 7, 0.2);
    outline: none;
}

#add-item-container textarea {
    margin-bottom: 1rem;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 1rem;
    font-family: inherit;
    width: 100%;
    box-sizing: border-box;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    min-height: 100px;  /* Added for better textarea sizing */
    max-height: 300px;
    resize: vertical;   /* Allows vertical resizing only */
}

#add-item-container textarea:focus {
    border-color: var(--accent-color);
    box-shadow: 0 0 8px rgba(19, 98, 7, 0.2);
    outline: none;
}

#add-item-container button {
    background-color: var(--accent-color);
    color: #fff;
    border: none;
    border-radius: 6px;
    padding: 0.75rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    width: 100%;
    font-weight: bold;
}

#add-item-container button:hover {
    background-color: var(--royal-blue);
    transform: translateY(-2px);
}

#add-item-container button:active {
    transform: scale(0.98);
}
