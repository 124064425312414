:root {
    --royal-blue: #003366;
    --accent-color: #136207;
    --accent-color-hover: #104f05;
    --background-color: #f8f8f8;
    --text-color: #333;
    --shadow-color: rgba(0, 0, 0, 0.1);
}

.modal {
    font-size: 16px;
    background-color: var(--background-color);
    border-radius: 12px;
    box-shadow: 0 10px 30px var(--shadow-color);
    padding: 30px;
    max-width: 500px;
    width: 90%;
    text-align: center;
    animation: fadeIn 0.5s ease-out, slideIn 0.5s ease-out;
    margin: 0 auto; /* Center the modal horizontally */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideIn {
    from { transform: translateY(-20px); }
    to { transform: translateY(0); }
}

.modal-header {
    font-size: 24px;
    font-weight: bold;
    color: var(--accent-color);
    margin-bottom: 20px;
    border-bottom: 2px solid var(--accent-color);
    padding-bottom: 15px;
    position: relative;
    overflow: hidden;
    width: 100%; /* Ensure full width */
    text-align: center; /* Center the text */
}

.modal-header::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(to right, var(--accent-color), var(--accent-color-hover));
    transform: translateX(-100%);
    animation: borderSlide 2s ease-in-out infinite;
}

@keyframes borderSlide {
    0% { transform: translateX(-100%); }
    50% { transform: translateX(0); }
    100% { transform: translateX(100%); }
}

.modal-content {
    margin-bottom: 20px;
    color: var(--text-color);
    line-height: 1.6;
    width: 100%; /* Ensure full width */
    text-align: center; /* Center the text */
}

.modal-actions {
    display: flex;
    justify-content: center;
    width: 100%; /* Ensure full width */
}

.modal-login-link a {
    background-color: var(--accent-color) !important;
    color: white;
    padding: 12px 25px;
    border-radius: 30px;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px var(--shadow-color);
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.modal-login-link a:hover {
    background-color: var(--accent-color-hover);
    transform: translateY(-2px);
    box-shadow: 0 6px 8px var(--shadow-color);
}

/* Override Popup component default styles */
.popup-content {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    margin: 0 !important;
    background: var(--background-color);
    width: 350px !important;
    padding: 0 !important;
    border: none !important;
    border-radius: 16px !important;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2) !important;
    overflow: hidden !important;
    animation: popIn 0.5s cubic-bezier(0.26, 0.53, 0.74, 1.48);
}

@keyframes popIn {
    0% { opacity: 0; transform: scale(0.8); }
    100% { opacity: 1; transform: scale(1); }
}

.popup-overlay {
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(8px);
    animation: fadeIn 0.3s ease-out;
}