#my-orders-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    gap: 20px;
    font-family: 'Noto Serif', serif;
}

#my-orders-content {
    flex: 1;
    padding: 20px 125px;
}

#my-orders-content h1 {
    font-family: 'Noto Serif', serif;
    color: var(--royal-blue);
    text-align: left;
    font-size: 2.5rem;
    border-bottom: 2px solid var(--accent-color);
    padding-bottom: 10px;
    width: 100%;
    margin-bottom: 20px;
}

#orders-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.order-item {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.order-item:hover {
    background-color: #f0f4f8;
}

.order-item p {
    margin: 0;
    font-size: 1.2rem;
    word-wrap: break-word;
}

@media (max-width: 768px) {
    #my-orders-content {
        padding: 20px 30px;
    }

    #my-orders-content h1 {
        font-size: 2rem;
    }

    .order-item {
        padding: 15px;
    }

    .order-item p {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    #my-orders-content {
        padding: 20px 15px;
    }

    #my-orders-content h1 {
        font-size: 1.8rem;
    }

    .order-item {
        padding: 10px;
    }

    .order-item p {
        font-size: 0.9rem;
    }
}
