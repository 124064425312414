.notification {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: black;
    color: white;
    padding: 12px 24px;
    border-radius: 10px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 1000;
    font-size: 1rem;
}

.notification.show {
    opacity: 1;
}