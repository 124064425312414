.products-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
}

.products-content {
    flex: 1;
    padding: 2rem;
}

.products-item {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    margin-bottom: 2rem;
    transition: transform 0.3s ease;
}

.products-item:hover {
    transform: translateY(-5px);
}

.products-input {
    margin-bottom: 1rem;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.products-input:focus {
    border-color: var(--accent-color);
    box-shadow: 0 0 8px rgba(19, 98, 7, 0.2);
    outline: none;
}

.products-button {
    background-color: var(--accent-color);
    color: #fff;
    border: none;
    border-radius: 6px;
    padding: 0.75rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-right: 1rem;
}

.products-button:hover {
    background-color: var(--royal-blue);
    transform: translateY(-2px);
}

.products-button:active {
    transform: scale(0.98);
}

.products-delete-button {
    background-color: #f44336;
}

.products-delete-button:hover {
    background-color: #d32f2f;
}