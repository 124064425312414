/* Pagination.css */

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    transition: all 0.3s ease;
}

.pagination .pagination-arrow,
.pagination .page-number {
    margin: 0 5px;
    font-family: 'Noto Serif', serif;
    padding: 10px 15px;
    border: 1px solid #ddd;
    background-color: #fff;
    color: var(--accent-color);
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s, transform 0.3s;
}

.pagination .pagination-arrow:hover,
.pagination .page-number:hover {
    background-color: var(--accent-color);
    color: #fff;
    transform: scale(1.1);
}

.pagination .pagination-arrow.disabled {
    background-color: #f1f1f1;
    color: #ccc;
    cursor: not-allowed;
}

.pagination .page-number.active {
    background-color: var(--accent-color);
    color: #fff;
    border-color: var(--accent-color);
}

.pagination .ellipsis {
    margin: 0 5px;
    padding: 10px 15px;
    color: var(--accent-color);
}
