#order-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    gap: 20px;
    font-family: 'Noto Serif', serif;
}

#order-content {
    flex: 1;
    padding: 20px 125px;
}

#order-content h1 {
    font-family: 'Noto Serif', serif;
    color: var(--royal-blue);
    text-align: left;
    font-size: 2.5rem;
    border-bottom: 2px solid var(--accent-color);
    padding-bottom: 10px;
    width: 100%;
    margin-bottom: 20px;
}

#order-summary {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

#order-items {
    background-color: #f0f4f8;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.order-item {
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
    padding: 20px 0;
    transition: background-color 0.3s ease;
}

.order-item p {
    margin-left: 20px;
}

.order-status, .item-status {
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 4px;
    color: #fff;
}

.order-status.processing, .item-status.processing {
    background-color: #f39c12;
}

.order-status.partly_shipped, .item-status.partly_shipped {
    background-color: #3498db;
}

.order-status.shipped, .item-status.shipped {
    background-color: #8e44ad;
}

.order-status.partly_delivered, .item-status.partly_delivered {
    background-color: #e67e22;
}

.order-status.delivered, .item-status.delivered {
    background-color: #2ecc71;
}
