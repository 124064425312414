/* ProductList.css */

/* Existing styles... */

.product-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.product-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 12px;
    padding: 16px;
    margin: 16px;
    width: 280px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-card::after {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(135deg, rgba(0, 51, 102, 0.1), rgba(19, 98, 7, 0.1));
    opacity: 0;
    transition: opacity 0.3s ease;
}

.product-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
}

.product-card:hover::after {
    opacity: 1;
}

.product-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.product-card:hover .product-image {
    transform: scale(1.05);
}

.product-details {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    align-items: center;
}

.product-name,
.product-description,
.product-price {
    text-align: center;
    margin: 8px 0;
}

.product-name {
    color: var(--accent-color);
    font-weight: bold;
    font-size: 1.2rem;
}

.product-price {
    font-size: 1.25rem;
    font-weight: bold;
    color: var(--royal-blue);
}
