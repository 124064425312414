/* Base Styles */

#cart-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    gap: 20px;
    font-family: 'Noto Serif', serif;
}

#pycommerce-title {
    font-size: 4rem !important;
    text-align: center !important;
    margin-bottom: 0;
    padding: 0;
    line-height: 1;
    font-family: 'Great Vibes', cursive !important;
    transition: transform 0.3s ease !important;
}

#pycommerce-title a {
    text-decoration: none;
    color: var(--accent-color);
}

#pycommerce-title:hover {
    transform: scale(1.1);
}

#cart-content {
    flex: 1;
    padding: 20px 125px;
}

#cart-content h1 {
    font-family: 'Noto Serif', serif;
    color: var(--royal-blue);
    text-align: left;
    font-size: 2.5rem;
    border-bottom: 2px solid var(--accent-color);
    padding-bottom: 10px;
    width: 100%;
    margin-bottom: 20px;
}

#cart-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
}

#cart-main {
    flex: 3;
    margin-right: 20px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

#cart-sidebar {
    flex: 1;
    padding: 20px;
    background-color: #f0f4f8;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    height: fit-content;
}

#cart-items {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

#cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
    padding: 20px 0;
    transition: background-color 0.3s ease;
}

#cart-item-left {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 20px;
}

#cart-item-left img {
    width: 100%;
    max-width: 150px;
    height: auto;
    object-fit: cover;
    margin-right: 20px;
    flex-shrink: 0;
}

#cart-item-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#cart-item-name {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 5px;
}

#cart-item-quantity-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
}

#cart-item-quantity-select,
#custom-quantity-input {
    margin-right: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    width: 60px;
    text-align: center;
    transition: all 0.3s ease;
    outline: none;
}

#custom-quantity-input {
    width: 80px;
}

#cart-item-quantity-select:focus,
#custom-quantity-input:focus {
    border-color: #888;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

#quantity-update-button {
    background-color: #28a745;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 1.2rem;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

#quantity-update-button:hover {
    transform: scale(1.1);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

#cart-item-remove {
    background-color: #e74c3c;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 8px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
    font-size: 1.2rem;
}

#cart-item-remove:hover {
    background-color: #c0392b;
}

#cart-item-price {
    font-size: 1.3rem;
    font-weight: bold;
    color: var(--royal-blue);
    text-align: right;
}

#checkout-button {
    padding: 12px 20px;
    background-color: var(--accent-color);
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1.8rem;
    width: 100%;
    text-align: center;
    font-family: 'Great Vibes', cursive;
}

#checkout-button:hover {
    background-color: #3b5998;
}

#continue-shopping {
    display: block;
    margin: 20px auto 0;
    padding: 10px 20px;
    font-size: 1.3rem;
    color: #fff;
    background-color: var(--royal-blue);
    text-decoration: none;
    text-align: center;
    border-radius: 4px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    width: 190px;
}

#continue-shopping:hover {
    background-color: #3b5998;
    transform: scale(1.05);
}

/* Responsive Styles */

@media (max-width: 1024px) {
    #cart-content {
        padding: 20px 60px;
    }
}

@media (max-width: 768px) {
    #cart-content {
        padding: 20px 30px;
    }

    #cart-container {
        flex-direction: column;
        align-items: center; /* Center the cart items container */
    }

    #cart-main {
        margin-right: 0;
        width: 100%;
        padding: 20px 15px; /* Add padding to cart main container */
    }

    #cart-sidebar {
        max-width: 80%;
        width: auto;
        margin-top: 20px;
        align-self: center; /* Center the sidebar */
    }

    #cart-item {
        flex-direction: column;
        align-items: center;
        padding: 20px 10px; /* Add padding to cart item container */
        margin-bottom: 20px; /* Add margin between items */
    }

    #cart-item-left {
        width: 100%;
        justify-content: center;
    }

    #cart-item-left img {
        max-width: 130px; /* Increase image size slightly */
    }

    #cart-item-details {
        margin-top: 10px;
        align-items: center;
        text-align: center;
    }

    #cart-item-remove {
        margin-left: 0;
        margin-top: 10px;
    }

    #cart-item-name {
        font-size: 1.3rem;
    }

    #cart-item-price {
        text-align: center;
        margin-top: 10px;
    }

    #checkout-button {
        font-size: 1.5rem;
    }

    #continue-shopping {
        width: auto;
        font-size: 1.1rem;
        padding: 8px 16px;
    }
}

@media (max-width: 480px) {
    #pycommerce-title {
        font-size: 3rem !important;
    }

    #cart-content h1 {
        font-size: 2rem;
    }

    #cart-content {
        padding: 20px 15px;
    }

    #cart-item {
        padding: 20px 10px; /* Add padding to cart item container */
        margin-bottom: 20px; /* Add margin between items */
    }

    #cart-item-left img {
        max-width: 100px; /* Increase image size slightly */
        margin-right: 0;
        margin-bottom: 10px;
    }

    #cart-item-left {
        flex-direction: column;
        align-items: center;
    }

    #cart-item-details {
        align-items: center;
        text-align: center;
    }

    #cart-item-price {
        text-align: center;
    }

    #cart-item-name {
        font-size: 1.2rem;
    }

    #quantity-update-button {
        font-size: 1rem;
    }

    #checkout-button {
        font-size: 1.2rem;
    }

    #continue-shopping {
        font-size: 1rem;
        padding: 6px 12px;
    }

    #cart-sidebar {
        max-width: 100%;
        width: auto;
        padding: 15px;
    }
}

.cart-button-super-animate {
    animation: cartButtonSuperPulse 1.5s ease-in-out;
}

@keyframes cartButtonSuperPulse {
    0% {
        transform: scale(1);
        background-color: #1b5e20;
    }
    25% {
        transform: scale(1.2);
        background-color: #4caf50;
    }
    50% {
        transform: scale(1.1);
        background-color: #1b5e20;
    }
    75% {
        transform: scale(1.2);
        background-color: #4caf50;
    }
    100% {
        transform: scale(1);
        background-color: #1b5e20;
    }
}