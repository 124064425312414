/* login.css */

@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Noto+Serif:wght@400;700&display=swap');

:root {
    --royal-blue: #003366;
    --accent-color: #136207;
    --background-color: #f8f8f8;
    --text-color: #333;
}

/* Base Styles */
body {
    font-family: 'Noto Serif', serif;
    background-color: var(--background-color);
    margin: 0;
    padding: 0;
}

.login-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
}

.login-page .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 1rem;
    animation: fadeIn 0.5s ease-in-out;
}

.login-page .pycommerce-title {
    font-family: 'Great Vibes', cursive;
    font-size: clamp(2.5rem, 8vw, 4rem);
    margin: 0;
    padding: 1rem;
    text-align: center;
    color: var(--accent-color);
    transition: transform 0.3s ease;
    cursor: pointer;
}

.login-page .pycommerce-title:hover {
    transform: scale(1.1);
}

.login-page .login-container {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    width: 90%;
    max-width: 400px;
    margin: 1.5rem auto;
    transition: transform 0.3s ease;
}

.login-page .login-container:hover {
    transform: translateY(-5px);
}

.login-page .login-container h2 {
    color: var(--royal-blue);
    margin-bottom: 1.5rem;
    margin-top: 0;
    font-size: 1.8rem;
    position: relative;
}

.login-page .login-container h2::after {
    content: '';
    width: 50px;
    height: 3px;
    background-color: var(--accent-color);
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.login-page form {
    display: flex;
    flex-direction: column;
}

.login-page input {
    margin-bottom: 1rem;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.login-page input:focus {
    border-color: var(--accent-color);
    box-shadow: 0 0 8px rgba(19, 98, 7, 0.2);
    outline: none;
}

.login-page button {
    background-color: var(--accent-color);
    color: #fff;
    border: none;
    border-radius: 6px;
    padding: 0.75rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    width: 100%;
    font-weight: bold;
}

.login-page button:hover {
    background-color: var(--royal-blue);
    transform: translateY(-2px);
}

.login-page button:active {
    transform: scale(0.98);
}

.login-page p {
    font-size: 0.85rem;
    color: #666;
    margin-top: 1rem;
    text-align: center;
}

.login-page .login-link {
    margin-top: 1rem;
    text-align: center;
    font-size: 0.9rem;
}

.login-page .login-link a {
    color: var(--royal-blue);
    text-decoration: none;
    position: relative;
    transition: color 0.3s ease;
}

.login-page .login-link a::after {
    content: '';
    position: absolute;
    width: 0%;
    height: 1px;
    display: block;
    background: var(--royal-blue);
    transition: width 0.3s;
    bottom: -2px;
    left: 0;
}

.login-page .login-link a:hover {
    color: var(--accent-color);
}

.login-page .login-link a:hover::after {
    width: 100%;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive Styles */
@media (max-width: 480px) {
    .login-page .content {
        padding: 1rem 0.5rem;
    }

    .login-page .login-container {
        width: 85%;
        padding: 1.5rem;
        margin: 1rem auto;
        max-width: 300px;
    }

    .login-page input,
    .login-page button {
        padding: 0.6rem;
    }

    .login-page .login-container h2 {
        font-size: 1.4rem;
        margin-bottom: 1rem;
    }

    .login-page p,
    .login-page .login-link {
        font-size: 0.8rem;
    }
}

@media (max-width: 360px) {
    .login-page .login-container {
        width: 90%;
        padding: 1rem;
        margin: 0.5rem auto;
    }

    .login-page input,
    .login-page button {
        padding: 0.5rem;
    }

    .login-page .login-container h2 {
        font-size: 1.2rem;
        margin-bottom: 0.75rem;
    }
}
