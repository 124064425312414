/* header.css */
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Playfair+Display:wght@400;700&family=Noto+Serif:wght@400;700&display=swap');

:root {
    --royal-blue: #003366;
    --accent-color: #136207;
    --accent-color-hover: #104f05; /* Added this variable */
    --background-color: #f8f8f8;
    --text-color: #333;
    --shadow-color: rgba(0, 0, 0, 0.1); /* Added this variable */
}


/* Header Styles */
.header {
    background-color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.header-top, .header-bottom {
    padding: 10px;
}

.header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-left, .header-right {
    display: flex;
    align-items: center;
}

.pycommerce-title {
    font-family: 'Great Vibes', cursive;
    padding-left: 20px;
    font-size: 2.5rem;
    color: var(--accent-color);
    transition: transform 0.3s ease-in-out;
}

.pycommerce-title a {
    color: inherit;
    text-decoration: none;
}

.pycommerce-title:hover {
    transform: scale(1.1);
}

.nav-links {
    opacity: 0; /* Start with zero opacity */
    visibility: hidden; /* Hide the element */
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 60px;
    right: 10px;
    background-color: white;
    box-shadow: 0 4px 6px var(--shadow-color);
    border-radius: 8px;
    padding: 10px;
    z-index: 1000;
    max-height: 0; /* Start with zero height */
    overflow: hidden; /* Hide overflow content */
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Add transition for smooth effect */
}

.nav-links.open {
    opacity: 1; /* Fully visible */
    visibility: visible; /* Make the element visible */
    max-height: 500px; /* Set a max-height large enough to show all content */
}

/* New styles for header buttons */
.header-button {
    background-color: var(--accent-color);
    color: white !important;
    padding: 12px 25px;
    border-radius: 30px;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px var(--shadow-color);
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: none;
    cursor: pointer;
    margin-bottom: 10px;
}

.header-button:hover {
    background-color: var(--accent-color-hover);
    transform: translateY(-2px);
    box-shadow: 0 6px 8px var(--shadow-color);
}

.header-button:focus {
    outline: none;
}

.search-bar {
    width: 100%; /* Default to full width */
    max-width: 500px; /* Maximum width for larger screens */
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
}

.search-bar input {
    flex: 1;
    min-width: 0;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px 0 0 4px;
}

.search-button {
    padding: 8px 12px;
    background-color: var(--accent-color);
    color: white;
    border: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

@media (min-width: 768px) {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        height: 60px;
    }

    .header-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .header-bottom {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    .search-bar {
        max-width: 500px;
    }

    .header-right {
        order: 3;
    }
}

.header-left h1 {
    margin: 0;
}


.pycommerce-title {
    font-family: 'Great Vibes', cursive;
    padding-left: 20px;
    font-size: 2.5rem;
    color: var(--accent-color);
    transition: transform 0.3s ease-in-out;
}

.pycommerce-title:hover {
    transform: scale(1.1);
}

.header-center {
    flex: 1;
    display: flex;
    justify-content: center;
}

@media (max-width: 767px) {
    .header-top {
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
    }

    .pycommerce-title {
        font-size: 2.5rem;
        padding-left: 0;
        margin-bottom: 15px;
        width: 100%;
    }

    .header-right {
        width: 100%;
        justify-content: center;
        margin-top: 10px;
    }

    .nav-links {
        display: none; /* Hide nav links by default on smaller screens */
        flex-direction: column;
        align-items: flex-start;
        position: absolute;
        top: 60px;
        right: 10px;
        background-color: white;
        box-shadow: 0 4px 6px var(--shadow-color);
        border-radius: 8px;
        padding: 10px;
        z-index: 1000;
    }

    .nav-links.open {
        display: flex; /* Show when open */
    }

    .hamburger-icon {
        display: block; /* Show hamburger icon on smaller screens */
    }

    .header-right {
        position: relative;
    }

    .header-button {
        margin: 5px;
        padding: 10px 20px;
        font-size: 0.9rem;
    }

    .header-bottom {
        padding: 10px 0;
    }

    .search-bar {
        width: 75%; /* Smaller width for mobile devices */
    }

    .search-bar input {
        font-size: 14px;
    }

    .search-button {
        padding: 6px 10px;
    }
}

/* Adjust search bar for medium screens */
@media (min-width: 768px) and (max-width: 1024px) {
    .search-bar {
        width: 85%; /* Medium width for tablets */
    }
}

.hamburger-icon {
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--accent-color);
}
