.product-page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
}

.product-page-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    flex: 1;
}

.product-page-image-container {
    width: 50%;
    padding: 1rem;
}

.product-page-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.product-page-image:hover {
    transform: scale(1.05);
}

.product-page-details {
    width: 50%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.product-page-name {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: var(--royal-blue);
}

.product-page-description {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    line-height: 1.6;
}

.product-page-price {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: var(--accent-color);
}

.product-page-stock-remaining {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    color: var(--accent-color);
}

.product-page-add-to-cart-button {
    background: linear-gradient(135deg, #003366, #1b5e20);
    border: none;
    color: #fff;
    padding: 12px 24px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease, transform 0.2s ease;
    max-width: 200px;
}

.product-page-add-to-cart-button svg {
    margin-right: 8px;
}

.product-page-add-to-cart-button:hover {
    transform: translateY(-2px);
}

.product-page-add-to-cart-button:active {
    transform: translateY(0);
}

@media (max-width: 768px) {
    .product-page-content {
        flex-direction: column;
    }

    .product-page-image-container,
    .product-page-details {
        width: 100%;
    }
}
