/* General Styles */
body {
  font-family: "Noto Serif", serif;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
}

.home-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

.main-content {
  flex: 1;
  padding-top: 2rem;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* Product Card Styles */
.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin: 16px;
  width: 280px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-card::after {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(135deg, rgba(0, 51, 102, 0.1), rgba(19, 98, 7, 0.1));
  opacity: 0;
  transition: opacity 0.3s ease;
}

.product-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
}

.product-card:hover::after {
  opacity: 1;
}

.product-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
  transition: transform 0.3s ease;
}

.product-card:hover .product-image {
  transform: scale(1.05);
}

.product-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  width: 100%;
}

.product-name,
.product-description,
.product-price {
  text-align: center;
  margin: 8px 0;
}

.product-description {
  min-height: 100px;
  text-align: center;
  margin: 8px 0;
}

.cart-button {
  align-self: center;
  margin-top: auto;
  background: linear-gradient(135deg, #003366, #1b5e20);
  border: none;
  color: #fff;
  padding: 12px 24px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s ease, transform 0.2s ease;
  z-index: 1;
}

.cart-button svg {
  margin-right: 8px;
}

.cart-button:hover {
  transform: translateY(-2px);
}

.cart-button:active {
  transform: translateY(0);
}

.cart-button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(1);
  transition: opacity 0.6s, transform 0.6s;
  pointer-events: none;
  z-index: -1;
}

.cart-button:active::before {
  opacity: 1;
  transform: translate(-50%, -50%) scale(20);
  transition: opacity 0s, transform 0.6s;
}

/* Loading Spinner */
.loader {
  border: 8px solid rgba(0, 51, 102, 0.1);
  border-top: 8px solid var(--accent-color);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin: 100px auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Fade-In Animation */
.fade-in {
  animation: fadeIn 0.8s ease-in forwards;
  opacity: 0;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

/* Pagination Styles */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.pagination .page-number {
  background: #fff;
  color: var(--royal-blue);
  border: 1px solid var(--royal-blue);
  padding: 8px 12px;
  margin: 0 4px;
  border-radius: 4px;
  transition: background 0.3s, color 0.3s;
}

.pagination .page-number:hover,
.pagination .page-number.active {
  background: var(--accent-color);
  color: #fff;
}

.pagination .pagination-arrow {
  color: var(--royal-blue);
  cursor: pointer;
  transition: color 0.3s;
}

.pagination .pagination-arrow.disabled {
  color: #ccc;
  cursor: default;
}

.pagination .pagination-arrow:hover:not(.disabled) {
  color: var(--accent-color);
}

/* Media Queries */
@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    transition: none !important;
  }
}

.product-card a {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  position: relative;
  z-index: 1;
}

.product-card a:hover {
  text-decoration: none;
}